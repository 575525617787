<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard
            :height="'100%'"
            class="memo-inbox"
            v-show="!axiosLoading && !networkError && singleMemo"
          >
            <header-left
              slot="left-toolbar"
              :singleMemo="singleMemo"
              v-on:reloadMemo="reloadMemo"
              v-on:printPreview="downloadPdf"
              :heading="$t('card.permit-detail-title')"
            />
            <header-right slot="right-toolbar" v-if="false" />

            <p v-if="!singleMemo" class="text-center py-4">
              {{ $t("label.inbox-detail-is-loading") }}
            </p>

            <v-list-item
              three-line
              v-if="singleMemo"
              class="table-responsive-container"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-avatar
                    v-on="on"
                    v-bind="attrs"
                    :class="{
                      'sender__avatar-desktop': !$vuetify.breakpoint.smAndDow,
                    }"
                  >
                    <img
                      :src="singleMemo.memo.avatar_path"
                      alt="approver"
                      v-if="singleMemo && singleMemo.memo.approver_name"
                    />
                  </v-list-item-avatar>
                </template>
                <span>{{ singleMemo.memo.approver_name }}</span>
              </v-tooltip>
              <v-list-item-content class="table-responsive">
                <v-list-item-title class="headline">
                  <span style="cursor: auto">
                    {{
                      singleMemo && singleMemo.memo.hal
                        ? $t("label.memo-subject") + ": " + singleMemo.memo.hal
                        : "Hal"
                    }}
                  </span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-show="parseInt(singleMemo.is_memo_direksi) === 1"
                        class="ma-2 theme-bg4"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t("badge.directors-memo") }}
                      </v-chip>
                    </template>
                    <span>{{ $t("tooltip.directors-memo") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-show="parseInt(singleMemo.has_delegated) === 1"
                        class="ma-2 theme-bg"
                        dark
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t("badge.has-delegated") }}
                      </v-chip>
                    </template>
                    <span>{{ $t("tooltip.memo-has-delegated") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="parseInt(singleMemo.has_attachments) === 1"
                        class="att-icon"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ iconAttachment }}
                      </v-icon>
                    </template>
                    <span>{{ $t("tooltip.attachment-exist") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="parseInt(singleMemo.urgensi) === 1"
                        class="att-icon"
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ iconUrgent }}
                      </v-icon>
                    </template>
                    <span>{{ $t("tooltip.urgent") }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="parseInt(singleMemo.is_rahasia) === 1"
                        class="att-icon"
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ iconSecret }}
                      </v-icon>
                    </template>
                    <span>{{ $t("tooltip.secret") }}</span>
                  </v-tooltip>
                </v-list-item-title>
                <p
                  style="cursor: auto"
                  class="v-list-item__subtitle text--bold"
                >
                  {{
                    `${$t("label.memo-number")} : ${
                      singleMemo ? singleMemo.memo.nomor : ""
                    }`
                  }}
                </p>
                <div
                  class="d-inline-flex justify-space-between align-content-center align-center"
                >
                  <v-menu
                    bottom
                    right
                    offset-y
                    origin="center center"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-list-item-subtitle
                            v-if="
                              singleMemo &&
                              parseInt(singleMemo.memo.is_bom) === 0
                            "
                            class="sender-info-button text-bolder mini-shake-infinite"
                            v-on="{ ...tooltip, ...menu }"
                            v-bind="attrs"
                            style="max-width: 600px"
                            >{{
                              `${
                                singleMemo.pengirim.bagian
                                  ? singleMemo.pengirim.bagian + "-"
                                  : ""
                              } ${singleMemo.pengirim.unit}`
                            }}</v-list-item-subtitle
                          >

                          <v-list-item-subtitle
                            v-else
                            class="sender-info-button text-bolder mini-shake-infinite"
                            v-on="{ ...tooltip, ...menu }"
                            v-bind="attrs"
                            style="max-width: 600px"
                            >{{
                              `${
                                singleMemo ? singleMemo.pengirim.name : ""
                              } - ${singleMemo ? singleMemo.pengirim.unit : ""}`
                            }}</v-list-item-subtitle
                          >
                        </template>
                        <span>{{ $t("tooltip.sender-info") }}</span>
                      </v-tooltip>
                    </template>

                    <div class="popup-card">
                      <table class="sender-info-table">
                        <tr>
                          <td class="desc">{{ $t("label.signed-by") }}</td>
                          <td>:</td>
                          <td style="padding-left: 0.8rem">
                            {{
                              singleMemo ? singleMemo.memo.approver_name : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="desc">{{ $t("label.revised-by") }}</td>
                          <td>:</td>
                          <td v-if="singleMemoSignerHistory">
                            <ol>
                              <li
                                v-for="item in singleMemoSignerHistory"
                                :key="item.id"
                              >
                                {{ item.nama }}
                              </li>
                            </ol>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </v-menu>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                        text
                        icon
                        color="blue lighten-2 ml-3"
                        @click="detailIsOpen = !detailIsOpen"
                      >
                        <v-icon>{{ iconInfo }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("tooltip.detail-info") }}</span>
                  </v-tooltip>
                </div>
              </v-list-item-content>

              <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>

              <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs">
                    {{
                      singleMemo
                        ? $date(singleMemo.memo.signed_at).format("DD MMM YYYY")
                        : ""
                    }}
                  </span>
                </template>
                <span>{{
                  singleMemo
                    ? $t("tooltip.received-at") +
                      " " +
                      $date(singleMemo.memo.signed_at).fromNow()
                    : ""
                }}</span>
              </v-tooltip>
            </v-list-item>
            <v-card-text v-show="detailIsOpen" class="px-4">
              <v-row>
                <v-col
                  cols="12"
                  class="rounded-lg theme--light v-sheet--outlined"
                >
                  <p>Rincian Kiriman</p>
                  <table>
                    <tr>
                      <td style="vertical-align: top; width: 10%">
                        {{ $t("text.recipients") }}
                      </td>
                      <td style="vertical-align: top; width: 1%">:</td>
                      <td style="vertical-align: top; width: 89%">
                        <ol>
                          <li v-for="item in recipients" :key="item.id_hash">
                            {{ item.nama }}
                            <span v-if="item.comment"
                              ><strong class="text-uppercase">{{
                                getApproval(item.is_approved)
                              }}</strong>
                              {{ `${$t("text.with-comment")} ${item.comment}` }}
                            </span>
                            <span v-else>[{{ item.izin_prinsip_status }}]</span>
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("text.received-at") }}</td>
                      <td>:</td>
                      <td class="pl-2">
                        {{
                          singleMemo
                            ? $date(singleMemo.memo.signed_at).format(
                                "DD MMMM YYYY HH:mm"
                              ) + " WIB"
                            : ""
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("text.reply-at") }}</td>
                      <td>:</td>
                      <td class="pl-2">
                        {{
                          singleMemo
                            ? $date(singleMemo.memo.reply_at).format(
                                "DD MMMM YYYY HH:mm"
                              ) + " WIB"
                            : ""
                        }}
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
            <froalaView
              v-if="singleMemo && singleMemo.memo.isi"
              v-model="singleMemo.memo.isi"
              class="px-4 py-3"
            ></froalaView>
            <p
              class="text-center pa-4"
              v-html="
                $t(
                  'text.content-not-available-or-you-are-not-have-right-to-view'
                )
              "
              v-if="singleMemo && !singleMemo.memo.isi"
            ></p>

            <div
              class="attachment-list mb-2"
              v-if="singleMemo && singleMemo.memo_attachments.length > 0"
            >
              <span class="d-block pl-3 mt-4 text-bolder text-smaller">{{
                $t("label.attachment-list")
              }}</span>
              <div class="table-responsive-container">
                <div class="table-responsive">
                  <ul>
                    <li
                      v-for="(item, index) in singleMemo.memo_attachments"
                      v-bind:key="index"
                      v-bind:value="item.id"
                      class="file--list"
                    >
                      <a
                        href="javascript:;"
                        class="server--file"
                      >
                        <span>{{ index + 1 }}.</span>
                        {{ item.file_name }} - {{ item.size | formatSize }}

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              large
                              class="ml-2"
                              color="blue"
                              v-on:click="downloadAttachment(item)"
                              v-on="on"
                              v-bind="attrs"
                            >
                              <v-icon>{{ iconDownload }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("tooltip.download-this-file") }}</span>
                        </v-tooltip>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <h3>{{ $t("text.memo-details") }}</h3>
                  <h4 class="mt-3 mb-3">{{ $t("text.memo-recipients") }}</h4>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  xl="4"
                  v-for="item in recipients"
                  :key="item.nik"
                >
                  <v-list-item three-line class="permit__recipient-list">
                    <v-list-item-avatar>
                      <img
                        v-bind:src="item.avatar_path"
                        v-bind:alt="item.nik"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <span
                        class="approval__icon"
                        v-if="parseInt(item.is_approved) === 1"
                        >👍🏻</span
                      >
                      <span
                        class="approval__icon"
                        v-else-if="parseInt(item.is_approved) === 0 && item.comment != null"
                        >🚫</span
                      >
                      <span class="approval__icon" v-else></span>
                      <v-list-item-subtitle
                        >{{ item.nama ? item.nama : "Nama Penerima" }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="font-size-smaller"
                        >{{
                          item.readed_at
                            ? $t("text.readed-at") +
                              " " +
                              $date(item.readed_at).format("DD MMM YYYY HH:m") +
                              " WIB"
                            : $t("text.unread")
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{
                          item.comment
                            ? item.comment
                            : "[" + $t("text.no-answer-yet") + "]"
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>

            <hr class="card-footer" v-if="singleMemo && userIsDirPjp" />
            <v-card
              v-if="singleMemo"
              elevation="0"
              class="mt-2 d-flex justify-end no-box-shadow no-border v-sheet--outlined"
            >
              <v-tooltip bottom v-if="singleMemo">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    class="ma-2"
                    outlined
                    color="primary"
                    @click="downloadPdf()"
                  >
                    <v-icon>{{ iconPrint }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.print-preview") }}</span>
              </v-tooltip>

              <v-btn
                v-if="singleMemo && !hasDelegated && userIsDirPjp"
                class="ma-2"
                outlined
                color="purple"
                @click="setPermitAnswerDialog(true)"
              >
                <v-icon>{{ iconCheck }}</v-icon>
                {{ $t("button.do-disposition") }}
              </v-btn>

              <v-btn
                v-else-if="singleMemo && hasDelegated"
                class="ma-2"
                outlined
                color="primary"
                @click="setPermitHistoryDialog(true)"
              >
                <v-icon class="mr-2">{{ iconHistory }}</v-icon>
                {{ $t("button.show-disposition-history") }}
              </v-btn>
            </v-card>
          </BaseCard>

          <v-card
            v-if="(networkError || axiosLoading) && !singleMemo"
            class="pa-4"
          >
            <v-skeleton-loader
              class="mx-auto"
              max-width="100%"
              type="table-heading,divider, list-item-avatar-two-line, article: heading, paragraph, divider, actions"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-flex>

    <permit-answer-modal v-bind:permitId="permitId" v-bind:memoId="memoId" />
    <permit-answer-history-modal v-bind:singleMemo="singleMemo" />
    <broadcast-modal :memoId="memoId" :isSecretMemo="isSecretMemo" />
    <memo-validation-modal :singleMemo="singleMemo"></memo-validation-modal>

    <v-overlay v-bind:value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { http } from "@/http/http";
import permitAnswerModal from "@/components/commonComponents/PermitAnswerModal";
import PermitAnswerHistoryModal from "@/components/commonComponents/PermitAnswerHistoryModal.vue";
import broadcastModal from "@/components/commonComponents/BroadcastModal";
import memoValidationModal from "@/components/commonComponents/MemoValidationModal";
import { mapFields } from "vuex-map-fields";

import {
  mdiAttachment,
  mdiClockFast,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiAlertCircleOutline,
  mdiCloudDownload,
  mdiDatabaseClockOutline,
  mdiPrinter,
  mdiInformation,
  mdiTooltipCheck,
} from "@mdi/js";

export default {
  components: {
    headerLeft,
    HeaderRight,
    permitAnswerModal,
    broadcastModal,
    memoValidationModal,
    PermitAnswerHistoryModal,
  },
  data: () => ({
    DispositionModalDummyAvatar: "https://i.pravatar.cc/100",
    iconAttachment: mdiAttachment,
    iconUrgent: mdiClockFast,
    iconBom: mdiStarCircle,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconAlert: mdiAlertCircleOutline,
    iconInfo: mdiInformation,
    iconHistory: mdiDatabaseClockOutline,
    iconDownload: mdiCloudDownload,
    iconPrint: mdiPrinter,
    iconCheck: mdiTooltipCheck,
    overlay: false,
    detailIsOpen: false,
  }),
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("inbox", [
      "singleMemo",
      "axiosLoading",
      "singleMemoHistory",
      "inboxAlert",
      "inboxAlertMessage",
      "delegationHistory",
      "singleMemoSignerHistory",
    ]),
    ...mapState("user", ["currentUser"]),
    ...mapFields("pdfgenerator", ["controlValue"]),
    inboxAlert: {
      get() {
        mdiClockFast;
        return this.$store.state.inbox.inboxAlert;
      },
      set(val) {
        this.$store.commit("inbox/SET_INBOX_ALERT", val);
      },
    },
    htmlToPdfOptions() {
      return {
        margin: 2,
        pagebreak: {
          mode: ["avoid-all", "css", "legacy"],
        },
        filename: this.singleMemo
          ? this.strCut(this.singleMemo.memo.hal, 100) + ".pdf"
          : "undefined.pdf",
        image: {
          type: "jpeg",
          quality: 2,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: "cm",
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      };
    },
    userIsDirPjp() {
      if (!this.currentUser) return false;
      return ["DIREKTUR", "PJP", "PJP_KANPUS"].includes(
        this.currentUser.level_aplikasi_elemen
      );
    },
    hasDelegated() {
      if (!this.singleMemo) return true;
      return parseInt(this.singleMemo.memo.has_answered) === 1;
    },
    memoId() {
      if (!this.singleMemo) return null;
      return this.singleMemo.memo.id_hash;
    },
    permitId() {
      if (!this.singleMemo) return null;
      return this.singleMemo.memo.id_hash_recipient;
    },
    isSecretMemo() {
      if (!this.singleMemo) return false;
      return this.singleMemo.is_memo_rahasia == 1;
    },
    dispositionId() {
      if (!this.singleMemo || !this.singleMemo.dispositions) return null;
      return this.singleMemo.dispositions[0];
    },
    recipients() {
      if (!this.singleMemo || !this.singleMemo.penerima) return [];
      // result = this.singleMemo.penerima.map(({ nama }) => nama);
      return this.singleMemo.penerima;
      // return result.join();
    },
  },
  created() {
    this.reloadMemo();
    // this.getDisposition({
    //   bgProcess: true,
    //   type: "mydelegation",
    //   memoId: this.$route.params.id,
    // });
  },
  watch: {
    inboxAlert(val) {
      if (!val) return;
      setTimeout(() => {
        this.setInboxAlert(false);
      }, 5000);
    },
  },
  filters: {
    formatSize(size) {
      if (!size) return;
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
  },
  methods: {
    ...mapActions("inbox", [
      "loadInboxDetail",
      "setAxiosLoading",
      "setInboxAlert",
      "setPermitAnswerDialog",
      "setPermitHistoryDialog",
      "getDisposition",
      "loadSignerHistory",
    ]),
    // ...mapActions("disposition",["getDispositionTrack"]),
    reloadMemo() {
      this.loadInboxDetail(this.$route.params.id);
      // this.getDispositionTrack(this.$route.params.id);
      this.loadSignerHistory(this.$route.params.id);
    },
    downloadAttachment(file) {
      if (!file) return;
      // api/memo/{id}/attachments/{attachmentId}
      const options = {
        responseType: "blob",
      };

      this.updateOverlay(true);

      http
        .get(`/memo/${file.memo_id}/attachments/${file.id_hash}`, options)
        .then((response) => {
          if (response.status === 500) return this.updateOverlay(false);
          if (response) {
            let fileURL = window.URL.createObjectURL(new Blob([response]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", file.file_name);
            document.body.appendChild(fileLink);

            this.updateOverlay(false);
            fileLink.click();
          }
        });
    },
    
    async downloadPdf() {
      window.scrollTo(0, 0);
      this.updateOverlay(true);
      try {
        const response = await http.get(
          `/memo/myrevision/${this.singleMemo.memo.id_hash}/getpdf`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              mode: "no-cors",
            },
            responseType: "blob",
          }
        )
        var file = new Blob([response], { type: 'application/pdf' });
        var url = URL.createObjectURL(file);
        window.open(url, '', 'width=800,height=768');
      }
      catch (error) {
        console.error(error);
        this.updateOverlay(false);
      }
      finally {
        this.updateOverlay(false);
      }
    },
   
    onProgress(progress) {
      this.progress = progress;
      console.info(`PDF generation progress: ${progress}%`);
    },
    startPagination() {
      // console.log(`PDF has started pagination`);
    },
    hasPaginated() {
      // console.log(`PDF has been paginated`);
    },
    async beforeDownload() {
      //param{ html2pdf, options, pdfContent }
      // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
      // 	const totalPages = pdf.internal.getNumberOfPages()
      // 	for (let i = 1; i <= totalPages; i++) {
      // 		pdf.setPage(i)
      // 		pdf.setFontSize(10)
      // 		pdf.setTextColor(150)
      // 		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
      // 	}
      // }).save()
    },
    hasDownloaded() {
      this.pdfDownloaded = true;
      this.updateOverlay(false);
    },
    domRendered() {
      this.contentRendered = true;
    },
    onBlobGenerate() {},
    updateOverlay(val) {
      this.overlay = val;
    },
    getApproval(val) {
      if (!val) return "";
      if (val == 1) return this.$t("text.agree");
      else return this.$t("text.reject");
    },
  },
};
</script>
<style>
.sender__avatar-desktop {
  height: 75px !important;
  width: 75px !important;
}
</style>